.btn , button, input[type="submit"], input[type="reset"], input[type="button"]
    border-radius: $br-small
    padding: 2rem 2rem
    display: inline-block
    cursor: pointer
    transition: $transition
    font-weight: 800
    color: $white
    box-shadow: 0 
    border: 0
    &:hover 
        // transform: scale(1.05)

    &.btn-icon 
        display: flex 
        align-items: center
        justify-content: center
        > img 
            width: 2.2rem 
            height: 2.2rem 
            object-fit: contain
            margin-right: 1.5rem

    &.btn-blue 
        background: $blue
        &:hover
            background: $dark

    &.btn-blue-80 
        background: $blue-80
        &:hover
            background: $dark-80

    &.btn-success
        background: $success
        &:hover
            background: $blue
    &.btn-reset
        background: $dark-60
        &:hover
            background: $dark

    
.button-holder
    display: flex
    flex-wrap: wrap
    gap: 1.5rem 