body
    padding: 2rem
    margin: 0
    background: $dark
    overflow-x: hidden
    color: $white
    min-height: 100vh
    font-family: $regular

$tmpSpacing: 2rem  
    
#sidebar
    background: $blue
    padding: 2rem
    width: 370px
    position: fixed
    left: #{$tmpSpacing}
    top: #{$tmpSpacing}
    height: calc(100% - 2 * #{$tmpSpacing})
    overflow-y: auto
    border-radius: $br-small

#main-content
    padding: 2rem
    width: calc(100% - 370px - #{$tmpSpacing})
    margin-left: auto
    border-radius: $br-small
    background: $dark-80
    min-height: calc(100vh - #{$tmpSpacing} * 2)


@media screen and ( max-width:  1500px )
    #sidebar 
        width: 300px

    #main-content
        width: calc(100% - 300px - #{$tmpSpacing})
    

@media screen and ( max-width: 992px )
    #sidebar
        width: auto
        height: auto
        top: 0
        left: auto 
        right:  calc(#{$tmpSpacing} * 2)
        border-radius: 0 0 $br-small $br-small
        z-index: 999
        .logo
            max-width: 100px 
            margin: 0 
        .sidebar-info, .sidebar-center-wrapper, .sidebar-bottom-wrapper
            display: none
    #main-content
        width: 100%
    
    
