#keyboard
    display: flex 
    align-items: stretch
    position: fixed
    bottom: 0 
    left: 50%
    transform: translate(-50%, 0)
    z-index: 99 
    max-width: 760px
    backdrop-filter: blur(10px)
    background: rgba(102,102,102, 0.5) 
    border-radius: 0 $br-small 0 0
    width: 100%
    box-shadow: 0 -10px 30px rgba(0,0,0,.3)
    transition: ease-in all 400ms

    &.hidden
        transform: translate(-50%, 100%)
        opacity: 0




    .keyboard-closer
        background: $blue
        padding: 2rem 
        border-radius: $br-small 0 0 0
        > img 
            display: block 
            width: 2rem 
            height: 2rem 
            margin-left: auto
    
    .char-container
        padding: 2rem 



            
