#sidebar 
    display: flex 
    flex-direction: column
    justify-content: space-between
    align-items: center
    text-align: left 

    > div 
        width: 100%
        
    

    .sidebar-info 
        margin: 3rem 0

    .btn-blue-80
        width: 100%


    .sidebar-content-part
        &:not(:last-of-type)
            margin-bottom: 4rem 
        p 
            color: $blue-20

    nav ul 
        list-style: none 
        padding: 0 
        margin: 0
        li 
            padding: .5rem 0 
            a 
                color: $blue-20
                text-decoration: none
                font-weight: 300
                transition: $transition
                &:hover , .active
                    color: $white
                .active 
                    text-decoration: underline
                    font-weight: 800