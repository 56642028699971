@import '../../var'

.info-msg
    padding: 1.5rem 
    display: flex
    background: $dark-80
    border-radius: $br-small

    &.info-msg-blue 
        background: $blue-80
        
    
    > img 
        display: block 
        width: 2rem 
        height: 2rem 
        object-fit: contain
        margin-right: 1rem 
    p 
        margin: 0
