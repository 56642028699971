
.char-row-wrapper
    display: flex
    flex-wrap: wrap
    flex-direction: column
    gap: 10px 

div.char-row
    display: flex
    flex-wrap: wrap
    align-items: stretch
    justify-content: center
    gap: 10px 


.char
    background: rgba(0,0,0,.4)
    background-blend-mode: multiply
    aspect-ratio: 2/1
    padding: 12px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 2px
    color: #fff
    text-transform: uppercase
    font-weight: 800
    border: 2px solid transparent
    transition: all .1s ease-in-out

    &:hover , &.active
        background: $blue 
        border-color: $blue
        transform: scale(1.05)
