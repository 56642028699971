$blue: #124BEC
$blue-80: #416FEF
$blue-60: #6F8FF2
$blue-40: #9DAFF5
$blue-20: #CDD0F8
$blue-10: #E6E6FA

$dark: #001212
$dark-90: #0F1F1F
$dark-80: #334141
$dark-60: #667070 // Reset Button
$dark-40: #99A0A0
$dark-20: #CCD3D3
$dark-10: #E6E6E6
$dark-5: #F2F2F2

$success: #60A105

$black: #000
$white: #fff


$regular: 'Manrope', sans-serif

$transition: ease all 400ms
// Font Weights: 400, 700


$br-small: 3px 
$br-big: 10px 
$br-rounded: 1000px

