
.h-s, .h-m, .h-l, .h-xl, h1, h2, h3, h4, h5, h6
    font-family: $regular
    font-weight: 800
    line-height: 1.2
    text-transform: uppercase

    
.h-s
    font-size: 1.5rem

.h-m
    font-size: 2.3rem

.h-l
    font-size: 3.5rem
