@import './var'

@import './sidebar/main'
@import './template/main'
@import './components/main'
@import './typography/main'
@import './buttons/main'

// Add other Stylesheets like this: ,'./buttons', './slider'

// Run "npm run dev" to start
// Open Dev Sources in Chrome - Page Sources -> Overrides -> Enable, write something into it
// Ready to go ;)

$maxWidth: 1100px


html 
    font-size: 10px // 1rem = 10px

*
    font-family: $regular
    box-sizing: border-box

body 
    font-size: 1.5rem
    overflow-x: hidden


#ai-sketch 
    display: none

canvas
    display: block
    margin: 0 auto
    background: #000
    border-radius: 5px
    box-shadow: 0px 11px 20px 0px #00000047
    border: 2px #ffffff40 solid

.inner-max-width
    padding-left: 20px
    padding-right: 20px
    margin: 0 auto
    max-width: $maxWidth





// Sketch Design

.sketch-holder
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    gap: 1.5rem
    width: 100%
    margin-bottom: 1.5rem 
    position: relative 
    .sketch-inner-holder 
        gap: 1.5rem
        position: relative
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: flex-start
        flex-wrap: wrap
        padding-right: 7rem 
        z-index: 9

.sketch-direction-arrow
    background: $blue 
    padding: 2rem 
    border-radius: 0 $br-rounded $br-rounded 0
    position: absolute
    z-index: -1
    top: 50%
    left: 0
    transform: translate(0, -50%)
    width: 100%
    > img 
        display: block 
        width: 3rem 
        height: 3rem 
        margin-left: auto
        transform: rotate(-90deg)




.predicted-holder
    .predicted-wrapper
        background: $dark-90
        border-radius: $br-rounded 0 0 $br-rounded
        padding: 10rem
        text-align: center
        position: relative
        &::after
            content: ""
            width: 100vw
            height: 100%
            background: $dark-90
            position: absolute
            top: 0 
            left: 100%


        #predicted-char
            font-size: 8rem 
            font-weight: 800
            display: block 
            line-height: 1
        .h-s
            opacity: .3




#all-weights
    margin-top: 5rem 
    width: 100%
    padding: 2rem 
    background: rgba(0,0,0,.2)
    border-radius: $br-small 
    .char.weight
        padding: 0
        background: #000
        width: 7rem
        height: 7rem

        canvas 
            border-color: transparent 
            margin: 0 
            box-shadow: none 
            border-radius: 0 

.hidden
    display: none