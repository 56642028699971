#sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
#sidebar > div {
  width: 100%;
}
#sidebar .sidebar-info {
  margin: 3rem 0;
}
#sidebar .btn-blue-80 {
  width: 100%;
}
#sidebar .sidebar-content-part:not(:last-of-type) {
  margin-bottom: 4rem;
}
#sidebar .sidebar-content-part p {
  color: #CDD0F8;
}
#sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#sidebar nav ul li {
  padding: 0.5rem 0;
}
#sidebar nav ul li a {
  color: #CDD0F8;
  text-decoration: none;
  font-weight: 300;
  transition: ease all 400ms;
}
#sidebar nav ul li a:hover, #sidebar nav ul li a .active {
  color: #fff;
}
#sidebar nav ul li a .active {
  text-decoration: underline;
  font-weight: 800;
}

body {
  padding: 2rem;
  margin: 0;
  background: #001212;
  overflow-x: hidden;
  color: #fff;
  min-height: 100vh;
  font-family: "Manrope", sans-serif;
}

#sidebar {
  background: #124BEC;
  padding: 2rem;
  width: 370px;
  position: fixed;
  left: 2rem;
  top: 2rem;
  height: calc(100% - 2 * 2rem);
  overflow-y: auto;
  border-radius: 3px;
}

#main-content {
  padding: 2rem;
  width: calc(100% - 370px - 2rem);
  margin-left: auto;
  border-radius: 3px;
  background: #334141;
  min-height: calc(100vh - 2rem * 2);
}

@media screen and (max-width: 1500px) {
  #sidebar {
    width: 300px;
  }
  #main-content {
    width: calc(100% - 300px - 2rem);
  }
}
@media screen and (max-width: 992px) {
  #sidebar {
    width: auto;
    height: auto;
    top: 0;
    left: auto;
    right: calc(2rem * 2);
    border-radius: 0 0 3px 3px;
    z-index: 999;
  }
  #sidebar .logo {
    max-width: 100px;
    margin: 0;
  }
  #sidebar .sidebar-info, #sidebar .sidebar-center-wrapper, #sidebar .sidebar-bottom-wrapper {
    display: none;
  }
  #main-content {
    width: 100%;
  }
}
.info-msg {
  padding: 1.5rem;
  display: flex;
  background: #334141;
  border-radius: 3px;
}
.info-msg.info-msg-blue {
  background: #416FEF;
}
.info-msg > img {
  display: block;
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin-right: 1rem;
}
.info-msg p {
  margin: 0;
}

#keyboard {
  display: flex;
  align-items: stretch;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  max-width: 760px;
  backdrop-filter: blur(10px);
  background: rgba(102, 102, 102, 0.5);
  border-radius: 0 3px 0 0;
  width: 100%;
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.3);
  transition: ease-in all 400ms;
}
#keyboard.hidden {
  transform: translate(-50%, 100%);
  opacity: 0;
}
#keyboard .keyboard-closer {
  background: #124BEC;
  padding: 2rem;
  border-radius: 3px 0 0 0;
}
#keyboard .keyboard-closer > img {
  display: block;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
}
#keyboard .char-container {
  padding: 2rem;
}

.char-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

div.char-row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
}

.char {
  background: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  aspect-ratio: 2/1;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
}
.char:hover, .char.active {
  background: #124BEC;
  border-color: #124BEC;
  transform: scale(1.05);
}

#close-keyboard {
  cursor: pointer;
}

.h-s, .h-m, .h-l, .h-xl, h1, h2, h3, h4, h5, h6 {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
}

.h-s {
  font-size: 1.5rem;
}

.h-m {
  font-size: 2.3rem;
}

.h-l {
  font-size: 3.5rem;
}

.btn, button, input[type=submit], input[type=reset], input[type=button] {
  border-radius: 3px;
  padding: 2rem 2rem;
  display: inline-block;
  cursor: pointer;
  transition: ease all 400ms;
  font-weight: 800;
  color: #fff;
  box-shadow: 0;
  border: 0;
}
.btn.btn-icon, button.btn-icon, input[type=submit].btn-icon, input[type=reset].btn-icon, input[type=button].btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-icon > img, button.btn-icon > img, input[type=submit].btn-icon > img, input[type=reset].btn-icon > img, input[type=button].btn-icon > img {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
  margin-right: 1.5rem;
}
.btn.btn-blue, button.btn-blue, input[type=submit].btn-blue, input[type=reset].btn-blue, input[type=button].btn-blue {
  background: #124BEC;
}
.btn.btn-blue:hover, button.btn-blue:hover, input[type=submit].btn-blue:hover, input[type=reset].btn-blue:hover, input[type=button].btn-blue:hover {
  background: #001212;
}
.btn.btn-blue-80, button.btn-blue-80, input[type=submit].btn-blue-80, input[type=reset].btn-blue-80, input[type=button].btn-blue-80 {
  background: #416FEF;
}
.btn.btn-blue-80:hover, button.btn-blue-80:hover, input[type=submit].btn-blue-80:hover, input[type=reset].btn-blue-80:hover, input[type=button].btn-blue-80:hover {
  background: #334141;
}
.btn.btn-success, button.btn-success, input[type=submit].btn-success, input[type=reset].btn-success, input[type=button].btn-success {
  background: #60A105;
}
.btn.btn-success:hover, button.btn-success:hover, input[type=submit].btn-success:hover, input[type=reset].btn-success:hover, input[type=button].btn-success:hover {
  background: #124BEC;
}
.btn.btn-reset, button.btn-reset, input[type=submit].btn-reset, input[type=reset].btn-reset, input[type=button].btn-reset {
  background: #667070;
}
.btn.btn-reset:hover, button.btn-reset:hover, input[type=submit].btn-reset:hover, input[type=reset].btn-reset:hover, input[type=button].btn-reset:hover {
  background: #001212;
}

.button-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

html {
  font-size: 10px;
}

* {
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
}

body {
  font-size: 1.5rem;
  overflow-x: hidden;
}

#ai-sketch {
  display: none;
}

canvas {
  display: block;
  margin: 0 auto;
  background: #000;
  border-radius: 5px;
  box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.2784313725);
  border: 2px rgba(255, 255, 255, 0.2509803922) solid;
}

.inner-max-width {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1100px;
}

.sketch-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
}
.sketch-holder .sketch-inner-holder {
  gap: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-right: 7rem;
  z-index: 9;
}

.sketch-direction-arrow {
  background: #124BEC;
  padding: 2rem;
  border-radius: 0 1000px 1000px 0;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
}
.sketch-direction-arrow > img {
  display: block;
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  transform: rotate(-90deg);
}

.predicted-holder .predicted-wrapper {
  background: #0F1F1F;
  border-radius: 1000px 0 0 1000px;
  padding: 10rem;
  text-align: center;
  position: relative;
}
.predicted-holder .predicted-wrapper::after {
  content: "";
  width: 100vw;
  height: 100%;
  background: #0F1F1F;
  position: absolute;
  top: 0;
  left: 100%;
}
.predicted-holder .predicted-wrapper #predicted-char {
  font-size: 8rem;
  font-weight: 800;
  display: block;
  line-height: 1;
}
.predicted-holder .predicted-wrapper .h-s {
  opacity: 0.3;
}

#all-weights {
  margin-top: 5rem;
  width: 100%;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
#all-weights .char.weight {
  padding: 0;
  background: #000;
  width: 7rem;
  height: 7rem;
}
#all-weights .char.weight canvas {
  border-color: transparent;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
}

.hidden {
  display: none;
}